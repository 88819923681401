import {
  query,
  getDocs,
  limit,
  startAfter,
  orderBy,
  QueryDocumentSnapshot,
  DocumentData,
  WriteBatch,
  Transaction,
  doc,
  UpdateData,
  setDoc,
  where,
} from 'firebase/firestore'
import firestore from '../../firestore'
import { PlanningRecord } from '../../models/PlanningRecord'
import BaseService from '../BaseService'

class PlanningRecordService extends BaseService {
  readonly pageSize = 3
  private lastPaginationDoc: QueryDocumentSnapshot<PlanningRecord, DocumentData> | undefined
  fetchPlanningRecords = async (): Promise<PlanningRecord[]> => {
    const planningRecordsSnapshot = await getDocs(firestore.planningRecords())
    return planningRecordsSnapshot.docs.map(planningRecordDoc => planningRecordDoc.data())
  }

  fetchPaginatedPlanningRecords = async ({
    forceFirstPage = false,
    onPlanningRecordUpdated,
  }: {
    forceFirstPage?: boolean
    onPlanningRecordUpdated: (template: PlanningRecord) => void
  }) => {
    if (!this.lastPaginationDoc || forceFirstPage) {
      return this.fetchPlanningRecordsFirstPage({
        onPlanningRecordUpdated,
      })
    }
    return this.fetchPlanningRecordsNextPage({
      onPlanningRecordUpdated,
    })
  }

  private fetchPlanningRecordsFirstPage = async ({
    onPlanningRecordUpdated,
  }: {
    onPlanningRecordUpdated: (template: PlanningRecord) => void
  }) => {
    const { docs, data, unsubscribe } = await this.onSnapshot({
      query: query(
        firestore.planningRecords(),
        where('status', '==', 'awaiting-validation'),
        orderBy('createdTimestamp', 'desc'),
        limit(this.pageSize),
      ),
      onUpdated: onPlanningRecordUpdated,
    })
    this.lastPaginationDoc = docs?.[docs?.length - 1]
    return {
      data,
      unsubscribe,
    }
  }

  private fetchPlanningRecordsNextPage = async ({
    onPlanningRecordUpdated,
  }: {
    onPlanningRecordUpdated: (template: PlanningRecord) => void
  }) => {
    const { docs, data, unsubscribe } = await this.onSnapshot({
      query: query(
        firestore.planningRecords(),
        where('status', '==', 'awaiting-validation'),
        orderBy('createdTimestamp', 'desc'),
        startAfter(this.lastPaginationDoc),
        limit(this.pageSize),
      ),
      onUpdated: onPlanningRecordUpdated,
    })
    this.lastPaginationDoc = docs?.[docs?.length - 1] || this.lastPaginationDoc
    return {
      data,
      unsubscribe,
    }
  }

  approvePlanningRecord = ({
    transactionOrBatch,
    planningRecord,
  }: {
    transactionOrBatch?: WriteBatch | Transaction
    planningRecord: Pick<
      PlanningRecord,
      | 'id'
      | 'applicantTitle'
      | 'applicantFirstName'
      | 'applicantLastName'
      | 'applicantAddress1'
      | 'applicantAddress2'
      | 'applicantPostcode'
      | 'applicantTown'
      | 'categories'
    >
  }): void | Promise<void> => {
    const planningRecordsRef = firestore.planningRecords()
    const planningRecordRef = doc(planningRecordsRef, planningRecord.id)
    const planningRecordUpdate: UpdateData<PlanningRecord> = {
      ...planningRecord,
      status: 'validated',
    }
    if (transactionOrBatch) {
      // todo: for some reason I cant do this without type checking despite both transaction and writeBatch having the same function signature for update
      if (transactionOrBatch instanceof Transaction) {
        transactionOrBatch.update(planningRecordRef, planningRecordUpdate)
      }
      if (transactionOrBatch instanceof WriteBatch) {
        transactionOrBatch.update(planningRecordRef, planningRecordUpdate)
      }
      return
    }
    return setDoc(planningRecordRef, planningRecordUpdate, { merge: true })
  }

  discardPlanningRecord = ({
    transactionOrBatch,
    planningRecordId,
  }: {
    transactionOrBatch?: WriteBatch | Transaction
    planningRecordId: string
  }): void | Promise<void> => {
    const planningRecordsRef = firestore.planningRecords()
    const planningRecordRef = doc(planningRecordsRef, planningRecordId)
    const planningRecordUpdate: UpdateData<PlanningRecord> = {
      status: 'discarded',
    }
    if (transactionOrBatch) {
      // todo: for some reason I cant do this without type checking despite both transaction and writeBatch having the same function signature for update
      if (transactionOrBatch instanceof Transaction) {
        transactionOrBatch.update(planningRecordRef, planningRecordUpdate)
      }
      if (transactionOrBatch instanceof WriteBatch) {
        transactionOrBatch.update(planningRecordRef, planningRecordUpdate)
      }
      return
    }
    return setDoc(planningRecordRef, planningRecordUpdate, { merge: true })
  }
}

export default new PlanningRecordService()
