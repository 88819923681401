import { useEffect, ReactElement } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSelector } from '../../../redux/hooks'
import authSelectors from '../../../redux/modules/auth/selectors'

const PublicRoute = ({ children }: { children: ReactElement }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const loggedIn = useSelector(authSelectors.getLoggedIn)
  const emailVerified = useSelector(authSelectors.getEmailVerified)
  useEffect(() => {
    if (loggedIn && !emailVerified) {
      console.info('>>> PublicRoute redirect to /await-email-verification')
      navigate('/await-email-verification', {
        state: { from: location.state?.from || location.pathname },
        replace: true,
      })
      return
    }
  }, [loggedIn, emailVerified, navigate, location.state?.from, location.pathname])
  return children
}

export default PublicRoute
