export default {
  localFunctions: false,
  localFirestore: false,
  localAuth: false,
  localStorage: false,
  local: false,
  dev: false,
  prod: true,
  bucketBaseUrl: 'https://firebasestorage.googleapis.com/v0/b/leadgen-server-prod.appspot.com/o/',
  firebaseConfig: {
    apiKey: 'AIzaSyCBmmPPBy23O04TreQ1I2iyqnn1wtkuAlo',
    authDomain: 'leadgen-server-prod.firebaseapp.com',
    projectId: 'leadgen-server-prod',
    storageBucket: 'leadgen-server-prod.appspot.com',
    messagingSenderId: '641643929405',
    appId: '1:641643929405:web:7a1b424dda3ca32c112f54',
    measurementId: 'G-5DMMJ9RKJQ',
  },
  imgixBaseUrl: 'https://leadgen-prod.imgix.net/',
  stripeSecret:
    'pk_test_51OpA9rL2l4kVgcuSeagTr2oPOkGymXdE5Xvi9ytVnzn1M8PuM54Vg421Z10HP476DkY8g6Hk7xmVJC2OemFKCOst00aoxkr8ON',
  getAddressApiKey:
    'dtoken_hEDzcyiWMr1f-zlFoRT5ramoXjLydwsIJCUa8dQK0cibXeEg14gPr41eyhADhPZyoHfyuboeWzrJ2BhIlxILUova1p2XSkgbSDr_69qeDOHRzk7g0FQ-T2z1Jz7kEN8ck8fGurQB9Gp4DMFHLwLTo2gtnDhKuxzrmVVd6Wkh6uOQRwGWDqJZwdovmk2p06ioCh4xDAukD9XnkDiT_r6v5g',
}
